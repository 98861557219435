import Utils from './Utils.js'

class Car extends Utils {
    constructor(canvas) {
        super()
        this.canvas = canvas
        this.context = canvas.getContext('2d')

        this.baseCarUrl = require('@/assets/v3/car.png')
        this.baseCarImg = null

        this.appPositionUrl = require('@/assets/v3/car_p.png')
        this.appPositionImg = null

        this.position = []
        this.bottomPoint = null

        this.widthScale = 0.6

    }

    setWidthScale(val, callback) {
        let xl = this.canvas.width
        let yl = this.canvas.height

        this.widthScale = Number(val)
        this.context.clearRect(0, 0, xl, yl)
        this.getPostion(callback)
    }

    imgLoad(url) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => {
                resolve(img)
            }
            img.onerror = (err) => {
                console.log(err)
                reject()
            }
            img.src = url
        })
    }

    getPostion(callback) {
        this.drawCar('appPosition', () => {
            let imgData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height)
            let data = imgData.data

            let xl = this.canvas.width
            let yl = this.canvas.height


            let l = []
            for (let y = 0; y < yl; y++) {
                for (let x = 0; x < xl; x++) {
                    let ind = (y * xl + x) * 4;
                    let r = data[ind];
                    let g = data[ind + 1];
                    let b = data[ind + 2];
                    // let a = data[ind + 3];
                    if (r !=0 && g == 0 && b == 0) {
                        this.bottomPoint = {x,y}
                    }

                    if ((r == g && g == b) && r == 255) {
                        if (l.length == 0) {
                            l.push({ x, y })
                        } else {
                            let has = l.some(one => {
                                if (x - one.x < 10 && y - one.y < 10) {
                                    return true
                                } else {
                                    return false
                                }
                            })
                            if (!has) {
                                l.push({ x, y })
                            }
                        }
                    }

                }
            }
            this.position = l

            this.context.clearRect(0, 0, xl, yl)
            callback ? callback() : this.drawCar()
        })
    }

    drawCar(type = 'baseCar', callback) {
        if (this[type + 'Img']) {
            this.drawCarFun(type)
            callback && callback()
        } else {
            this.imgLoad(this[type + 'Url']).then(img => {
                this[type + 'Img'] = img
                this.drawCarFun(type)
                callback && callback()
            })
        }
    }

    drawCarFun(type = 'baseCar') {
        let baseSscale = this.widthScale * this._scaleByHeight()
        let scale = baseSscale > .8 ? .8 : baseSscale

        let el = this[type + 'Img']

        let ch = this.canvas.height,
            cw = this.canvas.width
        let iw = el.width,
            ih = el.height
        let dw = cw * scale,
            dh = ih / iw * dw

        let x = (cw - dw) / 2 + 1,
            y = (ch - dh) / 3 * (2 * scale / baseSscale);
        y = 60;

        this.context.drawImage(el, x, y, dw, dh)
    }
}

export default Car

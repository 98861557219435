<template>
  <div class="bg"></div>
</template>
<script>
export default{
  data(){
    return{
    }
  }
}
</script>
<style lang="scss" scoped="v3_bg">
.bg{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #121212 url('../../../assets/v3/bg_v3.png') center center /cover no-repeat;
  background-size: cover;
}
</style>
<template>
  <div class="v3_main">
    <canvas ref="carCanvas"></canvas>
    <apps ref="apps" v-if="car && car.position" :apps="appItems" :position="car.position" @appSelect="appSelect"></apps>
    <bottom v-if="car && car.position" :apps="appItems" :bottomP="car.bottomPoint" @appSelect="appSelect"></bottom>
  </div>
</template>
<script>
import Car from './Car.js'
import apps from './apps.vue'
import bottom from './bottom.vue'
export default {
  components: {
    apps,
    bottom
  },
  data() {
    return {
      canvasCar: null,
      car: null,
      appItems: [],
    }
  },
  mounted() {
    this.getAppItems()
    this.$nextTick(() => {
      this.init()
      this.drawFun()
    })
    window.$(window).on('resize', () => {
      this.$nextTick(() => {
        this.initStyle()
        this.reDraw()
      })
    })
  },
  methods: {
    init() {
      this.canvasCar = this.$refs.carCanvas
      this.car = new Car(this.canvasCar)
      this.initStyle()
    },

    getAppItems() {
      let url = '/app/info/queryUserApp'
      this.$http.post(url).then(({ detail }) => {
        this.appItems = detail
      })
    },
    initStyle() {
      let width = $(this.canvasCar).width(),
        height = $(this.canvasCar).height()

      this.canvasCar.width = width
      this.canvasCar.height = height

    },
    reDraw() {
      this.canvasCar.getContext('2d').clearRect(0, 0, this.canvasCar.width, this.canvasCar.height)
      this.drawFun()
    },
    drawFun(b = true) {
      if (b) {
        this.car.getPostion()
      }
    },
    appSelect(app) {
      this.getAppToken(app)
    },
    bankPage(url) {
      var id = 'bank_a'
      var a = null
      // 防止反复添加
      if (!document.getElementById(id)) {
        a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('target', '_blank')
        a.setAttribute('id', id)
        document.body.appendChild(a)
      } else {
        a = document.getElementById(id)
        a.setAttribute('href', url)
      }
      a.style.display = 'none'
      a.click()
    },
    getAppToken(app) {
      console.log(app)

      if (!app.appId) {
        return false
      }

      if (/^(\/|#)/.test(app.appAddr)) {
        this.$router.push({ path: app.appAddr })
        return
      }

      if (!app.addr) {
        this.bankPage(app.appAddr)
        return
      }

      var acount = this.$store.state.info
      let url = '/app/info/getAppToken'
      let params = {
        userName: acount.userName,
        password: acount.password,
        appId: app.appId,
      }
      this.$http.post(url, params).then(({ detail }) => {
        this.$set(app, 'token', detail.token)
        let url = app.appAddr
        console.log(app.appAddr)
        if (/\?/.test(url)) {
          url += '&token=' + app.token
        } else {
          url += '?token=' + app.token
        }
        console.log(url)
        this.bankPage(url)
      })
    },
  }
}

</script>
<style lang="scss" scoped="v3_main">
.v3_main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

}

</style>

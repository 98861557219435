<template>
  <div class="app_com" v-if="position&&position.length">
    <div class="app" v-for="(one,key) in appList" :key="one.x+one.y" :class="{'disable':one.app.disable}" :style="getStyle(one)" v-on:click="appClick(one,key)">
      <img :src="one.logo" alt="">
      <p>{{one.name}}</p>
    </div>
  </div>
</template>
<script>
import logo from '@/assets/center_v1_selected.png'

const appsList = [
  { name: '实时监控', logo: 'monitoring' },
  { name: '智慧驾管', logo: 'driver' },
  { name: '智能车管', logo: 'cartube' },
  { name: '数据中心', logo: 'data' }
]
export default {
  props: {
    position: {
      type: Array,
      default: () => []
    },
    apps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      logo,
    }
  },
  watch: {
    apps: function(val) {
      if (val.length) {
        console.log(val)
      }
    },
  },
  computed: {
    appList: function() {
      let list = appsList.map((one, key) => {
        let app = { cName: one.name, disable: true }
        if (this.apps) {
          this.apps.forEach(item => {
            if (item.cName == one.name) {
              app = item
              app.disable = false
            }
          })
        }

        let logo, logoAlive
        if (one.logo) {
          logo = require(`@/assets/apps/${one.logo}.png`)
          logoAlive = require(`@/assets/apps/${one.logo}_alive.png`)
        } else {
          logo = app.logoImg
          logoAlive = app.logoImg
        }

        return {
          logo,
          logoAlive,
          name: one.name,
          ...this.position[key] || {},
          app,
        }
      })
      return list
    },
  },
  methods: {
    getStyle(one) {
      return {
        top: one.y + 'px',
        left: one.x + 'px'
      }
    },
    setAppKey(app) {
      console.log(app)
      appsList.forEach((one, key) => {
        if (one.name == app.cName) {
          this.appKey = key
        }
      })
    },
    appClick(item, key) {
      this.appList.forEach(one => {
        if (one.name == item.name) {
          one.selected = true
        } else {
          one.selected = false
        }
      })
      if (key == 4 && this.appKey == key) {
        this.appKey = -1
        this.$emit('appSelect', item.app, true)
      } else if (item.app.appId || key == 4) {
        this.$emit('appSelect', item.app)
        this.appKey = key
      }
    }
  }
}

</script>
<style lang="scss" scoped="app_com">
.app_com {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  letter-spacing: .12vw;

  .app {
    cursor: pointer;
    color: #fff;
    position: absolute;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 1.2vw;

    &.alive {
      color: #43d0ff;
    }

    &.disable {
      cursor: inherit;
      opacity: .5;
    }

    img {
      margin-bottom: .2vh;
      width: 5vw;
    }
  }
}

</style>

<template>
  <div v-if="bottomP" class="v3_bottom" :style="domeStyle">
    <div class="app_box">
      <div class="app" v-for="(one) in appList" :key="one.name">
        <img :src="one.icon" alt="">
        <p>{{one.name}}</p>
      </div>
    </div>
    <div class="sub_app_box">
      <div class="list_box" v-for="(one,key) in subAppsList" :key="key">
        <div class="name_cell" v-for="item in one" :key="item.name" :class="{'click':item.click}" v-on:click="appClick(item)">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import img0 from '/src/assets/v3/b_0.svg'
import img1 from '/src/assets/v3/b_1.svg'
import img2 from '/src/assets/v3/b_2.svg'
const appList = [
  { name: '数字风控', icon: img0 },
  { name: '数字应用', icon: img1 },
  { name: '数字运营', icon: img2 }
]
const subAppsList = [
  [
    { name: '安全风控' },
    { name: '驾管风控' },
    { name: '车辆风控' },
    { name: '保险风控' },
    { name: '行业风控' },
    { name: '信用风控' }
  ],
  [
    { name: '智慧公交' },
    { name: '数字货运' },
    { name: '智慧约车' },
    { name: '智慧汽修' },
    { name: '智能场站台' },
    { name: '智慧固废' },
    { name: '智慧充电' },
    { name: '自动驾驶' },
    { name: '智慧氢能' },
    { name: '领域拓展商用车' }
  ],
  [
    { name: '渠道赋能' },
    { name: '网络货运' },
    { name: '智慧称重' },
    { name: '公共出行' },
    { name: '循环经济' },
    { name: '网约广告' },
    { name: '产业供应链' },
    { name: '数字双碳' },
    { name: '车路协同' }
  ]
]
export default {
  props: {
    bottomP: {
      type: Object,
      default: ()=>({})
    },
    apps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      appList,
      subAppsList,
      domeStyle:{}
    }
  },
  watch: {
    bottomP: function(val) {
      if (val) {
        this.$nextTick(() => {
          setTimeout(this.getStyle,200)
        })
      }
    },
    apps:function(val){
      this.subAppsList.forEach(l=>{
        l.forEach(one=>{
          val.forEach(app=>{
            if(app.cName == one.name){
              console.log(app.cName)
              one.app = app
              one.click = true
            }
          })
        })
      })
    }
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(this.getStyle,200)
    })
  },
  methods: {
    getStyle() {
      this.domeStyle = {
        top: (this.bottomP?this.bottomP.y:0) + 40 + 'px',
        '--line-heiht': this.getSpaceStyle() + 'px'
      }
    },
    getSpaceStyle() {
      let appDom = $('.v3_bottom .app_box')
      appDom[0] && console.log(appDom[0].offsetHeight + appDom[0].offsetTop)
      let appSubDom = $('.v3_bottom .sub_app_box')
      appSubDom[0] && console.log(appSubDom[0].offsetTop)
      if (appSubDom[0] && appDom[0]) {
        return appSubDom[0].offsetTop - (appDom[0].offsetHeight + appDom[0].offsetTop)
      }
      return 0
    },
    appClick(one){
      if (one.click) {
        this.$emit('appSelect',one.app)
      }
    }
  }
}

</script>
<style lang="scss" scoped="v3_bottom">
.v3_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  // background: rgba(0, 0, 0, .3);

  .app_box {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .app {
      color: #fff;
      text-align: center;
      font-size: 1.2vw;
      padding-bottom: .5vh;
      border-bottom: 1px dashed #fff;
      position: relative;

      &:after{
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 100%;
        width: 0px;
        border-right: 1px dashed #fff;
        height: var(--line-heiht);
      }

      img {
        margin-bottom: .2vh;
        width: 4vw;
        height: 4vw;
      }
    }
  }

  .sub_app_box {
    position: absolute;
    bottom: 2vh;
    width: 100%;
    display: flex;
    justify-content: space-around;

    .list_box {
      border: 1px dashed #fff;
      padding: 1vw 1vw;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .name_cell {
        max-height: 7em;
        letter-spacing: .5vh;
        writing-mode: vertical-lr;
        color: #fff;
        font-size: 1.2vw;
        margin-left: 1vw;
        margin-right: 1vw;

        &.click{
          cursor: pointer;
        }
      }
    }
  }
}

</style>

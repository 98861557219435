<template>
  <div class="logo">
    <img :src="logo" alt="">
  </div>
</template>
<script>
import logo from '/src/assets/v3/logo.png'
export default{
  data(){
    return{
      logo
    }
  }
}
</script>
<style lang="scss" scoped="logo_com">
.logo{
  position: absolute;
  top:2vh;
  left:2vh;
  z-index: 100;
  img{
    height: 8vh;
  }
}
</style>
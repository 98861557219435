<template>
  <div class="home">
    <bg></bg>
    <mainCom></mainCom>
    <logo></logo>
    <div class="top_out" v-on:click="logout">
      <img src="../assets/operate/nav_icon_logout.png" />
    </div>
  </div>
</template>
<script>
import bg from '@/components/center/v3/bg.vue'
import logo from '@/components/center/v3/logo.vue'
import mainCom from '@/components/center/v3/main.vue'
export default {
  data() {
    return {}
  },
  components: {
    bg,
    logo,
    mainCom,
  },
  methods: {
    logout() {
      this.$confirm('您确定退出该系统？', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let url = '/logout'
        this.$http.post(url).then(() => {
          this.$util.removeStor('token')
          this.$store.state.info = null
          window.location.reload()
          this.$router.push({ name: 'login' })
        })
      })
    }
  }
}

</script>
<style lang="scss" scoped="home_v2">
.home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .top_out {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 4000;
  }
}
</style>
